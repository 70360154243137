/**
 * Helpers
 */
 import {
  fromBreakpoint,
  onVisibilityChange, toBreakpoint
} from './helpers'

/**
 * Web standards
 */
import './cookieconsent'
import './softscroll'
import './lazyload'
import "./header.js";
import './fancybox';
import 'alpinejs/dist/cdn.min.js';

/**
 * Scripts which don't need dynamic import
 */
let wpcf7forms = document.querySelector( '.wpcf7-form' );
if ( wpcf7forms ) {
  const jquery = import( 'jquery' );
  global.jQuery = require( 'jquery' );
  window.jQuery = require( 'jquery' );
}

/**
 * Add scripts or styles based on loaded DOM
 */
addEventListener('DOMContentLoaded', () => {
  if (document.querySelectorAll('.swiper').length > 0) {
    const swiperCss = import('swiper/css')
    const swiperCssNavigation = import('swiper/css/navigation')
    const swiperCssPagination = import('swiper/css/pagination')
  }

  if (document.querySelectorAll('div[data-modal-target]').length > 0) {
    modal.init();
  }

})


/**
 * Dynamic import scripts
 */
const elements = [
  // '#header-video'
  '[data-aos]',
  '.aos-element',
  '.swiper',
  '[data-fancybox]'
];

[].forEach.call(elements, (element) => {
  if (element !== '') {
    if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {
      const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

        if (element === '[data-aos]' || element === '.aos-element') {
          const AOS = import('aos').then(AOS => {
            AOS.init({
              disable: function() {
                return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
              }
            });
          });
        }

        if ( element === '.swiper' ) {
          const SwiperCSS = import( 'swiper/css' );
          const SwiperCSSNavigation = import( 'swiper/css/navigation' );
          const SwiperCSSPagination = import( 'swiper/css/pagination' );
        }

        if (element === '[data-fancybox]') {
          const fancybox = import('./fancybox').then(() => {
            jQuery(document).ready(function($) {
              // Initialize Fancybox for elements with the 'data-fancybox' attribute
                $("[data-fancybox]").fancybox({
                    // Fancybox options here
                });
            });
          })
         
        }

      })

      if (window.addEventListener) {
        addEventListener('DOMContentLoaded', lazyloadHandler, false);
        addEventListener('load', lazyloadHandler, false);
        addEventListener('scroll', lazyloadHandler, false);
        addEventListener('resize', lazyloadHandler, false);
      } else if (window.attachEvent) {
        attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
        attachEvent('onload', lazyloadHandler);
        attachEvent('onscroll', lazyloadHandler);
        attachEvent('onresize', lazyloadHandler);
      }
    }
  }
});