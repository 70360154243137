const toggle = document.querySelector("#mobile-menu-click");
const toggleClose = document.querySelector("#mobile-menu-close");
const mobile_menu = document.querySelector(".mobile-menu");
// const mobile_menu_text = document.querySelector(".mobile-menu-text");
toggle.addEventListener("click", function () {
	mobile_menu.classList.toggle("opacity-1");
    mobile_menu.classList.toggle("visible");
    mobile_menu.classList.toggle("opacity-0");
    mobile_menu.classList.toggle("invisible");
});

toggleClose.addEventListener("click", function () {
	mobile_menu.classList.toggle("opacity-1");
    mobile_menu.classList.toggle("visible");
    mobile_menu.classList.toggle("opacity-0");
    mobile_menu.classList.toggle("invisible");
});